<template>
  <div class="d-flex-center-end">
    <select-available-segments :hide-selector="true"
                               :reload-accounts="true"
                               @loadSegment="$emit('loadSegment', $event)" @segmentsLoaded="segments = $event"/>
      <b-checkbox :checked="activeSegment.shared" @input="activeSegment.shared = $event" switch class="mr-3">Shared</b-checkbox>
    <b-btn id="getSharableLink"
           class="mr-3"
           @click="getSharableLink()"><fluency-icon type="linkOut"/> Copy Filter URL</b-btn>
    <b-dropdown :disabled="disabled"
                split
                right
                text="Save Filter"
                @click="saveSegment()">
      <b-dropdown-item @click="saveSegment(true)">Save As New Filter</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import SelectAvailableSegments from './selectAvailableSegments'
import _ from 'lodash'
export default {
  name: 'segmentFilterManager',
  components: { SelectAvailableSegments },
  props: ['disabled'],
  data () {
    return {
      segments: []
    }
  },
  computed: {
    activeSegment: {
      get () {
        return this.$store.getters.activeSegment || {}
      },
      set (segment) {
        this.$store.commit('setActiveSegment', segment)
      }
    },
    userRegistryId () {
      return this.$store.getters.user?.userRegistryId
    }
  },
  methods: {
    async saveSegment (saveNew) {
      let response
      const segment = _.cloneDeep(this.activeSegment)
      delete segment.owned
      segment.userRegistryId = this.userRegistryId

      if (this.activeSegment.savedSegmentId && !saveNew) {
        response = await this.$res.set.segment(segment)
      } else {
        const name = await this.$prompt('Enter a name for your filter:')

        if (!name) {
          this.$toast('You need to provide a name to save your filter', 'danger')
          return false
        }

        delete segment.savedSegmentId
        segment.name = name
        response = await this.$res.set.segment(segment)
      }
      if (response) {
        this.$store.dispatch('fetchUserSavedSegments', { forceFetch: true })
        this.activeSegment = response
        this.$toast('Filter saved', 'success')
      }
    },
    apply () {
      this.$emit('apply')
      this.$store.dispatch('reloadAccounts')
    },
    getSharableLink () {
      this.$emit('share')
    }
  }
}
</script>

<style scoped>

</style>
