<template>
  <tr class="custom-footer">
    <template v-for="field in tableData.fields"  :key="field.key + 'total'">

      <!--Name Column-->
      <td v-if="field.key === 'name'" class="name p-0 td-sticky" style="left:38px;">

        <!--Total / Average Control-->
        <div class="p-3 d-flex-center">
          <template v-if="!showAverage">
            <b-button variant="link" class="p-0 border-0 mr-2 font-weight-bold" @click="showAverage = !showAverage">Average</b-button>
            / <b class="ml-2">Total</b>
          </template>
          <template v-else>
            <b class="mr-2">Average</b> /
            <b-button variant="link" class="p-0 border-0 ml-2 font-weight-bold" @click="showAverage = !showAverage">Total</b-button>
          </template>
          <span class="mr-2">:</span>
          <info-tooltip title="Rows without metrics are not included in these totals." />
        </div>

        <!--Channel Name-->
        <template v-if="totalsFooterOpen">
          <template v-for="(channel, channelId) in totalsData.channel" :key="field.key + channelId + 'channelName'">
            <div class="p-3 border-top">
              {{getAdvertisingChannelLabel(channelId)}}
            </div>

          <!--Partner Name-->
          <template v-if="channelOpen[channelId]">
            <div v-for="(partner, partnerId) in totalsData.partner[channelId]" :key="field.key + partnerId +partner.advertisingChannelId + 'partnerName'" class="p-3 border-top">
              <span class="pl-5">{{getPartnerLabel(partnerId)}}</span>
            </div>
          </template>
          </template>

          <t-alert v-if="noMetrics" show variant="warning" class="p-3 mr-4 text-center">Metrics aren't available due to no spend.</t-alert>
        </template>
      </td>

      <!--DropDown Arrow-->
      <td v-else-if="field.key === 'selectRow'" class="selectRow td-sticky p-0 text-right" style="left: 0;">
        <div class="p-3">
          <b-button variant="link" class="text-success p-0" @click="totalsFooterOpen = !totalsFooterOpen">
            <fluency-icon :type="totalsFooterOpen ? 'chevronDown' : 'chevronRight'"/>
          </b-button>
        </div>

        <!--Channel DropDown Arrow-->
        <template v-if="totalsFooterOpen">
          <template v-for="(channel, channelId) in totalsData.channel" :key="field.key + channelId + 'channelArrow'" >
            <div class="text-right p-3 border-top">
              <b-button v-if="Object.keys(totalsData.partner[channelId]).length > 1" variant="link" class="text-success p-0" @click="toggleChannelOpen(channelId)">
                <fluency-icon :type="channelOpen[channelId] ? 'chevronDown' : 'chevronRight'"/>
              </b-button>
            </div>

            <!--Blank Space To Fill Out Rows-->
            <template v-if="channelOpen[channelId]">
              <div v-for="(partner, partnerId) in totalsData.partner[channelId]"
                   :key="field.key + partnerId + channelId + 'blankspace'"
                   class="text-right p-3 border-top"></div>
            </template>

          </template>
        </template>
      </td>

      <!--Main Total Row-->
      <td v-else :class="[field.key, 'p-0', field.thClass]">
        <div class="text-right font-weight-bold py-3 px-2 font-numeric" :class="{ 'delta-cell': totalsData && totalsData.previousTotals }">

          <delta-cell v-if="totalsData && totalsData.previousTotals && totalsData.previousTotals.hasOwnProperty(field.key)"
                      :previous="totalsData.previousTotals[field.key]"
                      :current="totalsData.total[field.key]"
                      :type="field.key"
                      :formatter="sharedColumnDefinitions[field.key].formatter" />
          <super-cell v-else :field="field" :row="totalsData.total" :show-average="showAverage" />

        </div>

        <!--Channel Totals-->
        <template v-if="totalsFooterOpen">
          <template v-for="(channel, channelId) in totalsData.channel" :key="field.key + channelId + 'channelTotal'" >
            <div class="text-right py-3 px-2 border-top">
              <super-cell :field="field" :row="channel" :show-average="showAverage" />
            </div>

            <!--Partner Totals-->
            <template v-if="channelOpen[channelId]">
                <div v-for="(partner, partnerId) in totalsData.partner[channelId]" :key="field.key + partner.partnerId + partnerId + 'partnerTotal'" class="text-right p-3 border-top">
                  <basic-partner-icon v-if="field.key === 'channels'" :value="parseInt(partnerId)" class="basic-partner-icon"></basic-partner-icon>
                  <super-cell v-else :field="field" :row="partner" :show-average="showAverage" />
                </div>
            </template>
          </template>
        </template>
      </td>
    </template>
  </tr>
</template>

<script>
import { PartnerName } from '../../assets/js/constants'
import BasicPartnerIcon from '@/components/common/basicPartnerIcon'
import SuperCell from '@/components/test/superCell'
import InfoTooltip from '@/components/common/forms/infoTooltip'
import DeltaCell from 'core-ui/components/common/deltaCell.vue'

import sharedColumnDefinitions from '@/components/table/sharedColumnDefinitions'

export default {
  name: 'ExpandFooter',
  components: { DeltaCell, InfoTooltip, SuperCell, BasicPartnerIcon },
  props: {
    tableData: {
      type: Object,
      required: true
    },
    totalsData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      totalsFooterOpen: false,
      showAverage: false,
      channelOpen: {},
      noMetrics: false
    }
  },
  methods: {
    getPartnerLabel (id) {
      const name = PartnerName(id)
      return name === 'Partner' ? id : name
    },
    getAdvertisingChannelLabel (id) {
      if (!id) return
      return this.$store.getters.advertisingChannels?.find(ch => ch.advertisingChannelId.toString() === id)?.channelLabel.replace('DDC RTB ', '').replace('Ingested', 'Legacy')
    },
    toggleChannelOpen (id) {
      if (typeof this.channelOpen[id] === 'undefined') {
        this.$setCompat(this.channelOpen, id, true)
      } else {
        this.channelOpen[id] = !this.channelOpen[id]
      }
    }
  },
  computed: {
    sharedColumnDefinitions () {
      return sharedColumnDefinitions
    }
  },
  watch: {
    totalsData: {
      handler (val) {
        if (Object.keys(val.partner).length === 0 && Object.keys(val.partner).length === 0) {
          this.noMetrics = true
        } else {
          this.noMetrics = false
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.custom-footer {
  > td {
    background: white;
    z-index: 5;
    position: sticky;
    bottom: 0;
    border-top: none;
    box-shadow: inset 0 1px $border-gray, inset 0 -1px $border-gray;
    > div {
      min-height: 35px;
      max-height: 35px;
    }
    > div.delta-cell {
      min-height: 70px;
      max-height: 70px;
    }
    &.name {
      box-shadow: inset -1px 0 $border-gray, inset 0 1px $border-gray, inset 0 -1px $border-gray;
    }
    &.td-sticky {
      z-index: 6;
    }
  }
  .basic-partner-icon img {
    height: 16px;
    width: auto;
  }
}
</style>
