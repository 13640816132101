<template>
    <div class="table-action-container position-absolute"
         style="right: 4px; top: 50%; transform: translateY(-50%);">

      <!-- <account-recommendation-modal :value="row.item"/> -->

      <sound-bytes v-if="row.item.soundBytes || row.item.soundBytesMonthOverMonth"
                   :value="row.item"/>

      <link-generator v-if="coLabUser && tableActionsAllowed && quickActions.questionnaireLink && !row.item.readOnlyMode"
                      :value="row.item" />
      <nuxt-link v-if="tableActionsAllowed && quickActions.editBasicSettings"
                 v-p-tooltip.top="{ value: 'Open Settings', showDelay: 1000 }"
                 class="table-action"
                 :to="{ query: { ...$route.query, settingsItemId: row.item.accountPlanId, settingsItemType: 'account' } }">
        <fluency-icon type="settings"/>
      </nuxt-link>
      <nuxt-link v-if="quickActions.accountPerformance"
                 v-p-tooltip.top="{ value: 'Account Performance', showDelay: 1000 }"
                 :to="`/insights/account-performance/?account=${row.item.accountPlanId}`"
                 class="table-action accountQuickLinksPerformance">
        <fluency-icon type="performance" />
      </nuxt-link>
      <quick-create-promoted-post v-if="tableActionsAllowed && quickActions.createPromotedPosts && row.item.facebookPageId"
                                  :account-plan-id="row.item.accountPlanId"
                                  :primary-domain="row.item.primaryDomain" />
    </div>
</template>

<script>
import LinkGenerator from '@/components/common/colab/linkGenerator'
import QuickCreatePromotedPost from '@/components/common/table/quickCreatePromotedPost'
import SoundBytes from '@/components/common/table/soundBytes'
// import AccountRecommendationModal from '@/components/common/table/accountRecommendationModal'

export default {
  name: 'tableAccountNameActions',
  components: { SoundBytes, QuickCreatePromotedPost, LinkGenerator },
  props: {
    row: {},
    tableActionsAllowed: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {}
  },
  computed: {
    quickActions () {
      return this.row.field.option?.quickActions ?? {}
    },
    coLabUser () {
      return this.$store.getters.user.internalUser || this.$store.getters.user?.capabilities?.CoLab
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
