<template>
  <div v-p-tooltip="{ value: labelList, disabled: !useTooltip }">
    <b-badge v-for="label in labels"
             :key="label.labelText"
             class="label-badge"
             :style="`background-color: ${label.labelColor};`">
      {{label.labelText}}
    </b-badge>
  </div>
</template>

<script>
export default {
  name: 'labelsDisplay',
  props: {
    labels: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    labelList () {
      return this.labels.map(label => label.labelText).join(', ') ?? ''
    },
    useTooltip () {
      return this.labelList.length > 33
    }
  }
}
</script>

<style scoped>
</style>
