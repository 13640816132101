<template>
  <div class="column-edit-container pr-3">
    <b-form-checkbox-group :checked="selectedColumnSetKeys"
                           name="defaultColumnSets"
                           value-field="key"
                           text-field="name"
                           stacked
                           @input="selectedColumnSetKeys = $event; applyColumnSets()">
      <b-form-checkbox v-for="defaultSet in defaultColumnSets" :key="defaultSet.key" :value="defaultSet.key" class="py-1">{{defaultSet.name}}</b-form-checkbox>
    </b-form-checkbox-group>
    <hr v-if="defaultColumnSets.length > 0 && customColumnSets.length > 0" class="border-top"/>
    <b-form-checkbox-group :checked="selectedColumnSetKeys"
                           name="customColumnSets"
                           value-field="key"
                           text-field="name"
                           stacked
                           @input="selectedColumnSetKeys = $event; applyColumnSets()">
      <template v-for="customSet in customColumnSets">
        <column-edit-actions :custom-set="customSet"
                             @saveColumn="saveCustomColumnSets"
                             @shareColumn="shareColumnSet($event)"
                             @removeColumn="removeCustomColumnSet($event)"
                             @saveColumnsIntoSet="saveFieldsIntoCustomColumnSet($event)"
        />
      </template>
    </b-form-checkbox-group>

    <hr v-if="defaultColumnSets.concat(customColumnSets).length > 0" class="border-top" />
    <b-form-group>
      <template #label>
        <div class="d-flex justify-content-between align-items-end">
        <b-form-checkbox :checked="selectedColumnSetKeys.length === 0 && !isFieldListDefault"
                         disabled
                         name="columnSets"
                         class="d-inline-block">Custom</b-form-checkbox>
          <b-button variant="link"
                    class="p-0"
                    @click="saveAsOpen = !saveAsOpen">Save As</b-button>
        </div>
      </template>

      <simple-cancel-save :value="''"
                          :show="saveAsOpen"
                          @update:show="saveAsOpen = !saveAsOpen"
                          @save="addCustomColumnSet({name: $event})" >
        <template #edit="{value, update}">
          <b-form-group label="Give it a name">
            <b-form-input :value="value"
                          @change="update($event)"
                          placeholder="My Columns"/>
          </b-form-group>
        </template>
      </simple-cancel-save>

      <b-form-checkbox-group :checked="visibleFields"
                             name="fieldGroups"
                             stacked
                            class="ml-3">
        <template v-for="(field, fieldIndex) in fieldList" :key="`${field.slot || field.key || field}-${fieldIndex}`">
          <div v-if="!excludedColumns.includes(field.slot || field.key || field)"
              :ref="`draggable${fieldIndex}`"
               @dragover.prevent="dragOver($event, fieldIndex)"
               @dragenter="dragEnter($event, fieldIndex)"
               @dragleave="dragLeave($event, fieldIndex)"
               @drop.prevent="drop($event, fieldIndex)"
               class="draggable hover-highlight py-1 px-2">
            <div :draggable="true"
                 @dragstart="dragStart($event, fieldIndex)"
                 @dragend="dragEnd($event, fieldIndex)"
                 class="drag-inside m-0 py-1 d-flex justify-content-between align-items-center">
              <b-form-checkbox :value="field"
                               class=""
                               @change="checkboxClick(field, fieldIndex)">{{ renderFieldText(field) }}</b-form-checkbox>
              <fluency-icon class="fluency-gray" type="move"/>

            </div>
          </div>
        </template>
      </b-form-checkbox-group>
    </b-form-group>
    <b-button variant="link" class="p-0 mb-2 ml-2" @click.stop="revert">Revert to Default</b-button>
  </div>
</template>

<script>
import columnSets, { columnSetSkeleton } from '@/components/table/columnSets'
import md5 from 'md5'
import SimpleCancelSave from '@/components/common/simpleCancelSave'
import _cloneDeep from 'lodash.clonedeep'
import sharedColumnDefinitions from '@/components/table/sharedColumnDefinitions'
import ColumnEditActions from '@/components/table/columnEditActions'

export default {
  name: 'columnEdit',
  props: {
    defaultFields: {
      type: Array // array of the default fields for the columnKey
    },
    columnKey: {
      type: String
    }
  },
  components: { ColumnEditActions, SimpleCancelSave },
  data () {
    return {
      dragging: false,
      draggingOver: [],
      excludedColumns: ['selectRow', 'budgetName', 'name', 'keywordText', 'creativePlan', 'extensionName', 'budgetShared'],
      visibleFields: [], // keeping a local copy of the visible fields.
      fieldList: [],
      selectedColumnSetKeys: [],
      customColumnSets: [],
      dragOverFieldIndex: -1,
      saveAsOpen: false,
      editNameOpen: false,
      shareColumnOpen: false
    }
  },
  watch: {
    columnKey: {
      immediate: true,
      handler: async function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          await Promise.all([
            this.$store.dispatch('table/getColumns', { columnKey: newVal, type: 'order' }),
            this.$store.dispatch('table/getColumns', { columnKey: newVal, type: 'sets' })
          ])

          this.$setCompat(this, 'visibleFields', this.$store.getters['table/visibleFields'](newVal, [...this.defaultFields]))
          this.$setCompat(this, 'customColumnSets', _cloneDeep(this.$store.getters['table/columns'][newVal]?.sets ?? []))
          this.selectedColumnSetKeys.splice(0, this.selectedColumnSetKeys.length, ...this.$store.getters['table/selectedColumnSets'](newVal))
          this.applyColumnSets(false)
        }
      }
    },
    defaultFields: {
      immediate: true,
      handler: function (newVal) {
        this.$setCompat(this, 'fieldList', [...(newVal || [])])
      }
    }
  },
  computed: {
    // sharedColumnDefinitionsMap () {
    //   return sharedColumnDefinitions
    // },
    defaultFieldMap () {
      return this.defaultFields.toMap(field => field.slot || field.key || field)
    },
    defaultColumnSets () {
      return columnSets.getConfigsForTable(this.columnKey, this.$store.getters.user.capabilities)
    },
    isFieldListDefault () {
      if (this.visibleFields.length !== this.defaultFields.length) {
        return false
      }
      // compare the field keys of fieldlist and default fields. return true if they're all equal in the same order
      return Array.zip(this.fieldList, this.defaultFields, (field, defaultField) => (field.slot || field.key || field) === (defaultField.slot || defaultField.key || defaultField))
        .every(val => val)
    }
  },
  methods: {
    async saveColumnOrder (isRevert) {
      const columnList = this.fieldList
        .filter(field => !!field)
        .map(field => field.slot || field.key || field)
        .map((fieldKey, indx) => ({
          columnName: fieldKey, // JSON.stringify(fieldKey),
          columnOrder: indx,
          visible: isRevert || this.visibleFields.findIndex(field => (field.slot || field.key || field) === fieldKey) !== -1
        }))
      await this.$store.dispatch('table/setColumns', {
        columnKey: this.columnKey,
        columnObject: {
          columnList,
          selectedColumnSets: [...this.selectedColumnSetKeys]
        },
        type: 'order'
      })
      this.$emit('update')
    },
    checkboxClick (field, fieldIndex) {
      const visibleIndex = this.visibleFields.indexOf(field)
      if (visibleIndex === -1) {
        this.visibleFields.splice(Math.min(fieldIndex, this.visibleFields.length), 0, field) // insert
      } else {
        this.visibleFields.splice(visibleIndex, 1) // remove
      }
      this.selectedColumnSetKeys.splice(0, this.selectedColumnSetKeys.length)
      this.$nextTick(() =>
        this.saveColumnOrder())
    },
    async revert () {
      const cfm = await this.$confirm('Are you sure you want to revert these fields to default?')
      if (cfm) {
        this.$setCompat(this, 'fieldList', [...this.defaultFields])
        this.$setCompat(this, 'visibleFields', [...this.defaultFields])
        this.selectedColumnSetKeys.splice(0, this.selectedColumnSetKeys.length)
        this.saveColumnOrder(true)
      }
    },
    renderFieldText (field) {
      const fieldKey = field.slot || field.key || field
      if (field.label) {
        return field.label
      } else if (this.defaultFieldMap[fieldKey] && this.defaultFieldMap[fieldKey].label) {
        return this.defaultFieldMap[fieldKey].label
      } else if (sharedColumnDefinitions[fieldKey]?.label) {
        return sharedColumnDefinitions[fieldKey].label
      } else {
        return this.$filters.convertFromCamelCase(fieldKey)
      }
    },

    dragStart: function (e, n) {
      e.dataTransfer.setData('text', n)

      this.dragging = true
      this.addClass(n, 'dragging')
    },
    dragEnd: function (e, n) {
      this.dragging = false
      this.removeClass(n, 'dragging')
    },
    dragEnter: function (e, fieldIndex) {
      this.addClass(fieldIndex, 'dragging-over')
    },
    dragLeave: function (e, fieldIndex) {
      this.removeClass(fieldIndex, 'dragging-over')
    },
    dragOver: function (e, fieldIndex) {
      if (this.dragOverFieldIndex !== fieldIndex) {
        this.addClass(fieldIndex, 'dragging-over')
      }
      this.dragOverFieldIndex = fieldIndex
    },
    drop: function (e, fieldIndex) {
      const droppedIndex = parseInt(e.dataTransfer.getData('text'))
      this.dragging = false
      this.$refs[`draggable${fieldIndex}`][0].classList.remove('dragging')
      this.removeClass(fieldIndex, 'dragging-over')
      this.draggingOver[fieldIndex] = false
      this.fieldList.move(droppedIndex, fieldIndex) // from extensions.js
      this.selectedColumnSetKeys.splice(0, this.selectedColumnSetKeys.length)
      this.saveColumnOrder()
      this.dragOverFieldIndex = -1
    },
    prevent: function (e) {
      e.preventDefault()
    },
    addClass (fieldIndex, className) {
      if (this.$refs[`draggable${fieldIndex}`]?.[0]?.classList) {
        this.$refs[`draggable${fieldIndex}`][0].classList.add(className)
      }
    },
    removeClass (fieldIndex, className) {
      if (this.$refs[`draggable${fieldIndex}`]?.[0]?.classList) {
        this.$refs[`draggable${fieldIndex}`][0].classList.remove(className)
      }
    },
    applyColumnSets (save = true) {
      this.visibleFields.splice(0, this.visibleFields.length)
      const originalFields = this.fieldList.splice(0, this.fieldList.length)
      const addedFields = new Set()
      const keys = this.selectedColumnSetKeys
        .flatMap(key => this.defaultColumnSets.find(s => s.key === key)?.fields ?? this.customColumnSets.find(s => s.key === key)?.fields ?? []) // concat the field lists together
      if (keys.length === 0) {
        keys.push(...(this.$store.getters['table/visibleFields'](this.columnKey, this.defaultFields).map(f => f.slot || f.key || f)))
      }
      keys.forEach((fieldKey, index) => {
        if (!addedFields.has(fieldKey)) { // don't add it twice
          addedFields.add(fieldKey)
          const field = this.defaultFieldMap[fieldKey]
          if (field) {
            this.visibleFields.push(field)
            this.fieldList.push(field)
            if (originalFields.includes(field)) {
              originalFields.splice(originalFields.indexOf(field), 1)
            }
          }
        }
      })
      this.fieldList.push(...originalFields)
      if (save) {
        this.saveColumnOrder()
      }
    },
    addCustomColumnSet ({ name }) {
      if (!name || name.length === 0) {
        return
      }
      const customFields = this.fieldList.filter(field => !!field)
        .map(field => field.slot || field.key || field)
        .filter(fieldKey => this.visibleFields.findIndex(visField => (visField.slot || visField.key || visField) === fieldKey) !== -1)

      const key = md5(`${name}:${customFields.join(',')}`)

      this.customColumnSets.push({
        ...columnSetSkeleton,
        key: key,
        name: name || 'My Column Set',
        tables: [this.columnKey],
        fields: this.fieldList.filter(field => !!field)
          .map(field => field.slot || field.key || field)
          .filter(fieldKey => this.visibleFields.findIndex(visField => (visField.slot || visField.key || visField) === fieldKey) !== -1)
      })

      this.selectedColumnSetKeys.splice(0, this.selectedColumnSetKeys.length, key)

      this.saveCustomColumnSets()
    },
    saveCustomColumnSets () {
      this.$store.dispatch('table/setColumns', {
        columnKey: this.columnKey,
        columnObject: _cloneDeep(this.customColumnSets),
        type: 'sets'
      })
      this.saveColumnOrder()
    },
    saveFieldsIntoCustomColumnSet (columnSet) {
      const fieldKeys = this.fieldList.filter(field => !!field)
        .map(field => field.slot || field.key || field)
        .filter(fieldKey => this.visibleFields.findIndex(visField => (visField.slot || visField.key || visField) === fieldKey) !== -1)

      columnSet.fields.splice(0, columnSet.fields.length, ...fieldKeys)
      this.selectedColumnSetKeys.splice(0, this.selectedColumnSetKeys.length, columnSet.key)
      this.saveCustomColumnSets()
    },
    async removeCustomColumnSet (columnSet) {
      this.customColumnSets.remove(columnSet)
      this.selectedColumnSetKeys.remove(columnSet.key)

      this.saveCustomColumnSets()
    },
    async shareColumnSet ({ columnSet, shareWith }) {
      const response = await this.$res.set.shareColumnSet({ forTable: `${this.columnKey}_sets`, columnSet, shareWith })
    }
  }
}
</script>

<style lang="scss" scoped>
  .draggable {
    cursor: grab;
    border-top: 2px solid white;

    * {
      cursor: grab;
    }

    .iconic {
      visibility: hidden;
    }

    &:hover .iconic {
      visibility: visible;
    }

    &:active {
      cursor: grabbing;
    }
  }

  .dragging-over {
    border-top: 2px solid #ccc;
  }

  .column-edit-container {
    max-height: 70vh;
    width: 15rem;
    overflow-y: scroll;
  }

  .customSet .hover-item {
    visibility: hidden;
  }
  .customSet:hover .hover-item {
    visibility: visible;
  }
</style>
