<template>
  <div v-if="expanded" class="border p-3 advanced-filters">
    <b-form-group v-if="filterData && typeof filterData.showOnlyErrors !== 'undefined'" class="d-inline">
      <b-form-checkbox :checked="filterData.showOnlyErrors" @change="filterData.showOnlyErrors = $event; updateData('showOnlyErrors' )" class="show-only-errors">Show Only Errors</b-form-checkbox>
    </b-form-group>
    <b-collapse :visible="expanded" @input="$emit('update:expanded', $event)">
      <div class="d-flex w-100">
        <div class="p-1">
          <div v-for="(filterVal, filterKey) in advancedFilterDataByPlanKey" :key="`filterKey-${filterKey}`" class="d-inline">
            <advanced-filter-editor v-model="advancedFilterDataByPlanKey[filterKey]"
                                    class="d-inline"
                                    :text="formatMenuItem(filterKey)"
                                    :show="activeFilterKey === filterKey"
                                    :type="planSkeleton ? planSkeleton[filterKey] : ''"
                                    :filter-key="filterKey"
                                    :options="options[filterKey]"
                                    @delete="removeFilter(filterKey)"
                                    @apply="applySegment()" />
          </div>
          <b-dropdown variant="ghost-secondary" text="Add Filter" menu-class="scrollable-menu" class="d-inline m-1">
            <b-form-input v-model="keyFilter" debounce="300" placeholder="Filter Available Fields"></b-form-input>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item :key="`field-${field}-${count}`" v-for="(field, count) in filteredAvailableSavedSegmentFields" @click="setFilter(field)">
              {{formatMenuItem(field)}}
            </b-dropdown-item>
          </b-dropdown>
          <b-btn v-if="Object.values(advancedFilterDataByPlanKey).length > 0" variant="ghost-secondary"  @click="clearFilters()">
            <fluency-icon type="x" class="small"/> Clear Filters
          </b-btn>
        </div>
        <div v-if="Object.values(advancedFilterDataByPlanKey).length > 0" class="flex-grow-1 text-right">
          <segment-filter-manager :hide-selector="true"
                                  class="mt-1"
                                  @apply="applySegment()"
                                  @share="getSharableLink()" />
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import SegmentFilterManager from './segmentFilterManager'
import AdvancedFilterEditor from './advancedFilterEditor'
import _ from 'lodash'

export default {
  name: 'advancedFilters',
  components: { AdvancedFilterEditor, SegmentFilterManager },
  props: ['advancedFilterData', 'showAdvancedFilters', 'expanded', 'options'],
  data () {
    return {
      filterData: {},
      advancedFilterDataByPlanKey: {},
      activeFilterKey: '',
      keyFilter: ''
    }
  },
  watch: {
    advancedFilterData: {
      handler (newValue, oldValue) {
        this.$setCompat(this, 'filterData', newValue)
      },
      immediate: true
    },
    activeSegment (newValue) {
      this.loadSegment(newValue)
    },
    numberOfActiveFilters (newVal) {
      this.$emit('activeFilterCounter', newVal)
    }
  },
  mounted () {
    this.getAvailableSavedSegmentFields()
  },
  computed: {
    availableSavedSegmentFields () {
      return this.$store.getters.savedSegmentFields
    },
    filteredAvailableSavedSegmentFields () {
      return this.keyFilter.length < 3 ? this.availableSavedSegmentFields : this.availableSavedSegmentFields.filter(field => field.toLowerCase().includes(this.keyFilter.toLowerCase()))
    },
    filterRoute () {
      return this.$route.query
    },
    planSkeleton () {
      const skeletonObject = _.cloneDeep(this.$store.getters.skeletons.account)
      if (skeletonObject) {
        Object.keys(skeletonObject).forEach(key => {
          if (key.endsWith('Id')) {
            delete skeletonObject[key]
          }
        })
      } else {
        this.$store.dispatch('fetchManageSkeletons')
        this.$toast('Error getting account skeleton, attempting to re-fetch', 'warning')
      }
      return skeletonObject
    },
    activeSegment: {
      get () {
        return this.$store.getters.activeSegment
      },
      set (segment) {
        this.$store.commit('setActiveSegment', segment)
      }
    },
    numberOfActiveFilters () {
      return Object.keys(this.advancedFilterDataByPlanKey).length
    }
  },
  methods: {
    processURLFilters (query) {
      const segment = {
        criteriaJson: {}
      }
      try {
        segment.criteriaJson = JSON.parse(query)
      } catch (e) {
        return
      }
      this.loadSegment(segment)
      this.applySegment()
      this.$router.push({ query: null }) // remove query so that things don't get processed again
    },
    clearFilters () {
      this.$store.dispatch('clearAllFilters')
    },
    formatMenuItem (val) {
      const strings = val.split('.')
      return strings.map(this.$filters.convertFromCamelCase).join(': ')
    },
    updateData () {
      this.$emit('update:advancedFilterData', this.filterData)
    },
    loadSegment (segment) {
      if (segment?.criteriaJson) {
        const filterPlan = {}
        Object.keys(segment.criteriaJson).forEach((key) => {
          filterPlan[key] = _.cloneDeep(segment.criteriaJson[key])
        })
        this.$setCompat(this, 'advancedFilterDataByPlanKey', filterPlan)
      } else if (!segment || Object.keys(segment).length === 0 || segment.savedSegmentId === 0) {
        this.advancedFilterDataByPlanKey = {}
      }

      this.$track.event('manage_current_segment', {
        label: segment.name,
        criteriaJson: segment?.criteriaJson
      }, 'manage')
    },
    async getAvailableSavedSegmentFields () {
      await this.$store.dispatch('fetchSavedSegmentFields')
      if (Object.keys(this.filterRoute).length > 0 && this.filterRoute.filter) {
        this.processURLFilters(this.filterRoute.filter)
      } else {
        this.loadSegment(_.cloneDeep(this.$store.getters.activeSegment))
      }
    },
    setFilter (field) {
      this.$setCompat(this, 'activeFilterKey', field)
      if (this.planSkeleton && this.planSkeleton[field] === 'java.lang.Boolean') {
        this.$setCompat(this.advancedFilterDataByPlanKey, field, false)
      } else {
        this.$setCompat(this.advancedFilterDataByPlanKey, field, [])
      }

      this.$track.event('manage_account_filter', {
        label: field
      }, 'manage')
    },
    removeFilter (filterKey) {
      delete this.advancedFilterDataByPlanKey[filterKey]
      this.activeFilterKey = ''
      this.$store.dispatch('removeSegmentCriteria', filterKey)
    },
    applySegment () {
      this.$store.dispatch('applySegment', this.advancedFilterDataByPlanKey)
    },
    getSharableLink () {
      this.$prompt({ defaultValue: `${document.location.href}?filter=${encodeURI(JSON.stringify(this.advancedFilterDataByPlanKey))}`, title: 'Copy the sharable link below' })
    }
  }
}
</script>

<style lang="scss">
.advanced-filters .custom-checkbox label.custom-control-label {
  font-weight: normal;
}

.advanced-filters .scrollable-menu.dropdown-menu {
  height: auto;
  max-height: 60vh;
  overflow:auto;
}
</style>
