<template>
  <div class="labels-container">
    <popover-cancel-save class="position-absolute absolute-center-y" style="right: 2px;" triggers="click clickOff"
                         title="Update Labels"
                         :value="item && item.labels ? item.labels : []"
                         @save="$emit('save', $event)"
                         :disable-editing="disableEditing">
      <template #edit="{ value, update }">
        <div class="mad-lib-container">
          <mad-lib-input :value="value || []" @input="update" disable-auto-focus placeholder="Add Labels"></mad-lib-input>
        </div>
      </template>

    </popover-cancel-save>
    <b-badge v-for="(label,idx) of (item?.labels ?? [])" :key="idx" class="p-2 mr-2">
      {{label}}
    </b-badge>
  </div>
</template>

<script setup>
import PopoverCancelSave from '@/components/common/popoverCancelSave'
import MadLibInput from 'core-ui/components/inputs/madLibInput.vue'
import { computed } from 'vue'
const props = defineProps({
  item: Object,
  field: Object,
  lazyLoadEditor: Boolean
})

defineEmits(['save'])

const disableEditing = computed(() => (props.field?.option && props.field.option === 'static') ?? false)

</script>

<style lang='scss' scoped>
  .labels-container {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

</style>
