<template>
  <div class="border p-3 rounded" v-if="show">
    <slot name="edit"
          :value="valueStore"
          :validate="isValid => isValueValid = isValid"
          :update="(val) => valueStore = val">
    </slot>
    <div class="m-0 pt-3 d-flex justify-content-end">
      <b-btn size="sm" tabindex="0" class="mr-3 focus-ring" variant="ghost-secondary" @click.stop="cancel()">
        {{cancelText}}
      </b-btn>
      <b-btn v-if="showSaveBtn" :disabled="!isValueValid" size="sm" type='submit' variant="primary" @click.stop.prevent="save()">
        {{okText}}
      </b-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimpleCancelSave',
  components: {  },
  props: {
    value: {
      type: [String, Object, Number, Boolean, Array]
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    okText: {
      type: String,
      default: 'Save'
    },
    show: {
      type: Boolean,
      default: false
    },
    showSaveBtn: {
      type: Boolean,
      default: true
    },
  },
  data () {
    return {
      isValueValid: true,
      valueStore: ''
    }
  },
  methods: {
    cancel () {
      this.isValueValid = true
      this.$emit('cancel', this.valueStore)
      this.$emit('update:show', false)
    },
    save () {
      this.$emit('input', this.valueStore)
      this.$nextTick(() => this.$emit('save', this.valueStore))
      this.$emit('update:show', false)
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
