<template>
  <span v-if="hasCampaigns || hasDefinedBudgetPartnerDirectives || isBudgetSubRow">
    {{budgetChannelLabel}}
  </span>

  <div class="inline-edit" v-else>
    <popover-cancel-save :value="budgetPlan.advertisingChannelId"
                @save="changeChannel($event)">
      <template  #edit="{value, valueRef, update}">
        <advertising-channel-selector :value="valueRef"
                                      @input="update" />
      </template>
    </popover-cancel-save>
    {{budgetChannelLabel}}
  </div>

</template>

<script setup>
import { defineAsyncComponent } from 'vue'
const AdvertisingChannelSelector = defineAsyncComponent(() => import('@/components/pages/manage/advertisingChannelSelector'))
const PopoverCancelSave = defineAsyncComponent(() => import('@/components/common/popoverCancelSave'))
</script>

<script>
// import PopoverCancelSave from './popoverCancelSave'
// import AdvertisingChannelSelector from '../pages/manage/advertisingChannelSelector'

export default {
  name: 'budgetChannelSelector',
  // components: { AdvertisingChannelSelector, PopoverCancelSave },
  props: ['value'],
  data () {
    return {}
  },
  computed: {
    budgetPlan () {
      return this.value
    },
    isBudgetSubRow () {
      return !!(this.value?.budgetId)
    },
    hasCampaigns () {
      return this.budgetPlan.campaignPlans && this.budgetPlan.campaignPlans.length > 0
    },
    hasDefinedBudgetPartnerDirectives () {
      return this.budgetPlan.partnerDirectiveList &&
        this.budgetPlan.partnerDirectiveList.some(p => p.budgetPlanPartnerDirectiveId)
    },
    budgetChannelLabel () {
      if (this.budgetPlan.advertisingChannelId) {
        let channel = this.$store.getters.advertisingChannels.find(c => c.advertisingChannelId === this.budgetPlan.advertisingChannelId)
        return (channel) ? channel.channelLabel : ''
      }
      return 'Search'
    },
    advertingChannelOptions () {
      return this.$store.getters.advertisingChannels.map(c => ({
        value: c.advertisingChannelId,
        text: c.channelLabel,
        disabled: !c.enabled
      }))
    }
  },
  methods: {
    changeChannel (newChannelId) {
      this.$emit('input', {
        ...this.budgetPlan,
        advertisingChannelId: newChannelId
      })

    }
  }
}
</script>

<style lang='scss' scoped>

</style>
