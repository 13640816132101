<template>
  <div v-if="budgetPlan">
    <label class="mb-0">Next Month Budget Change</label>
    <div class="mad-lib-container">
      <basic-mad-lib-input :value="nextBudgetAmount" format="CURRENCY" :min-currency-value="0" @input="updateAmount($event)"></basic-mad-lib-input>
      <t-button-inline v-if="nextBudgetAmount" severity="secondary" class="ml-3" @click="clearPending()">
        <fluency-icon type="delete"></fluency-icon>
      </t-button-inline>
    </div>
    <div v-if="showBudgetBalancer" class="mt-2">
      <b-button variant="link"
                class="px-0"
                @click="doBalance()">
        <fluency-icon type="budget" /> {{budgetBalancerButtonText}}
      </b-button>
      <div class="text-muted">Apply this recommendation to align your applied budget with your approved account budget.</div>
    </div>
    <div v-else-if="computedBudgetType !== 'LIFETIME'" class="d-flex-center">
      <b-form-checkbox :checked="nextBudgetAmount === 0" switch @input="placeBudgetOnHold($event)"></b-form-checkbox>
      <span>Place Budget On Manual Hold</span>
    </div>
  </div>
</template>

<script>
import _round from 'lodash.round'
import BasicMadLibInput from 'core-ui/components/inputs/basicMadLibInput.vue'

export default {
  name: 'nextMonthBudgetEditor',
  components: { BasicMadLibInput },
  props: {
    value: { // budgetPlan
      type: Object,
      required: true
    },
    accountPlan: {
      type: Object
      // required: true
    }
  },
  inject: {
    validate: {
      from: 'validate',
      default: () => {
        return (name, { isValid }) => isValid
      }
    }
  },
  data () {
    return {
      initialBudgetAmount: 0,
      magicNullBudget: -9090.90
    }
  },
  watch: {
    'budgetPlan.budgetPlanId': {
      handler: function (val) {
        const amt = this.budgetPlan?.nextBudgetAmount
        if (typeof amt === 'undefined' || amt === null || isNaN(amt)) {
          this.initialBudgetAmount = null
        } else {
          this.initialBudgetAmount = _round(parseFloat(amt ?? 0), 2)
        }
      },
      immediate: true
    }
  },
  computed: {
    budgetPlan () {
      return this.value
    },
    approvedAccountBudget () {
      if (this.accountPlan?.approvedAccountBudget > 0) {
        return _round(parseFloat(this.accountPlan.approvedAccountBudget), 2)
      }
      return null
    },
    appliedBudget () {
      const budgetAmount = this.accountPlan?.budget ?? this.accountPlan?.metricsDTO?.budget ?? -1
      if (budgetAmount >= 0) {
        return _round(parseFloat(budgetAmount), 2)
      }
      return null
    },
    nextBudgetAmount () {
      if (typeof this.budgetPlan.nextBudgetAmount === 'undefined' || this.budgetPlan.nextBudgetAmount === null || isNaN(this.budgetPlan.nextBudgetAmount)) {
        return null
      }

      return _round(parseFloat(this.budgetPlan.nextBudgetAmount), 2).toFixed(2)
    },
    accountBudgetDifference () {
      return this.approvedAccountBudget - this.appliedBudget
    },
    budgetBalancerButtonText () {
      return `${this.accountBudgetDifference < 0 ? 'Reduce' : 'Increase'} Budget by $${Math.abs(this.accountBudgetDifference).toFixed(2)}`
    },
    targetBudgetAmount () {
      return (this.initialBudgetAmount + this.accountBudgetDifference)
    },
    showBudgetBalancer () {
      return this.approvedAccountBudget != null &&
        this.appliedBudget != null &&
        this.accountBudgetDifference !== 0 &&
        this.nextBudgetAmount !== this.targetBudgetAmount &&
        this.targetBudgetAmount > 0
    },
    fieldState () {
      return {
        amount: this.validate('budget.amount', { isValid: this.nextBudgetAmount >= 0, message: 'Budget Amount must be greater than $0' })
      }
    },
    computedBudgetType () {
      if (this.budgetPlan?.budgetManagerConfiguration?.budgetBiasStrategy?.indexOf('SEGMENT') > -1) {
        return 'SEGMENTED'
      }
      return this.budgetPlan.budgetType
    }
  },
  methods: {
    updateAmount (value) {
      // handle case where you are removing a next months budget
      if (value === '' && this.initialBudgetAmount != null) { // If the user blanks out the value and the initial next months budget was something before.
        this.clearPending()
        return true
      } else {
        this.$emit('input', {
          ...this.budgetPlan,
          nextBudgetAmount: this.formatBudgetAmount(value)
        })
        return true
      }
    },
    formatBudgetAmount (rawValue) {
      if (isNaN(rawValue)) {
        return null
      }
      return parseFloat(rawValue).toFixed(2)
    },
    formatBudgetAmountString (val) {
      if (isNaN(val)) {
        return null
      }
      return `${this.formatBudgetAmount(val)}`
    },
    doBalance () {
      this.$emit('input', {
        ...this.budgetPlan,
        nextBudgetAmount: (this.initialBudgetAmount + this.accountBudgetDifference).toFixed(2)
      })
    },
    async clearPending () {
      const response = await this.$res.budget.clearPending({ budgetPlanId: this.budgetPlan.budgetPlanId })
      if (response) {
        this.$emit('input', {
          ...this.budgetPlan,
          nextBudgetAmount: undefined
        })
      }
    },
    placeBudgetOnHold (value) {
      if (value) {
        this.updateAmount(0)
      } else {
        this.updateAmount(this.initialBudgetAmount)
      }
    }
  }
}
</script>

<style lang='scss' scoped>

</style>
