<template>
  <div>
    <template v-if="criterion.name">
      {{criterion.name}}
    </template>
    <span v-else :class="classes.notEditable">Not currently editable</span>
  </div>
</template>

<script>
export default {
  name: 'criterionSpec',
  props: {
    criterion: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      classes: {
        notEditable: 'text-muted font-italic'
      }
    }
  },
  methods: {
    firstCharToLower (str) {
      return str.charAt(0).toLowerCase() + str.slice(1)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
