export const columnSetSkeleton = {
  key: '',
  name: '',
  tables: [],
  fields: []
}
const skeleton = columnSetSkeleton

const config = {
  configs: [
    {
      ...skeleton,
      key: 'status',
      name: 'Status and Configuration',
      tables: ['manage-budget', 'manage-campaign', 'manage-adGroup', 'manage-creative', 'manage-criterion', 'manage-keyword'],
      fields: [
        'selectRow',
        'budgetShared',
        'budgetName',
        'name',
        'creativePlan',
        'channels',
        'budgetManagerPaused',
        'servingStatus',
        'stat',
        'budget',
        'budgetType',
        'budgetBiasStrategy',
        'camp',
        'campaignGroupName',
        'campaignPlanName',
        'adGroupPlanName',
        'creativeType',
        'contentTemplateName',
        'pacing',
        'budgetAmount',
        'cost',
        'budgetCurrentTotalSpend',
        'allowReallocation',
        'bidAdjustmentPercentageOnUnderspend',
        'autoRemoveBlockingKeywordOnUnderspend',
        'useBroadMatchOnUnderspend',
        'openAdSchedulesOnUnderspend',
        'geoTargetExpansionAbsoluteMaxInMiles',
        'proratePartialMonth',
        'broadMatchBid',
        'isEnhancedBroadMatch',
        'phraseMatchBid',
        'exactMatchBid',
        'campaignLabels',
        'adGroupLabels',
        'attachedFeeds'
      ]
    },
    {
      ...skeleton,
      key: 'basic-account',
      name: 'Basic',
      tables: ['manage-account'],
      fields: [
        'selectRow',
        'name',
        'channels',
        'servingStatus',
        'score',
        'accountHold',
        'approvedAccountBudget',
        'accountAppliedBudget',
        'spendYesterday',
        'cost',
        'pacing',
        'approvedBudgetPacing',
        'clicks',
        'impressions',
        'clickThroughRate',
        'costPerClick',
        'conversions',
        'costPerConversion',
        'labels'
      ]
    },
    {
      ...skeleton,
      key: 'basic-budget',
      name: 'Basic',
      tables: ['manage-budget'],
      fields: [
        'selectRow',
        'budgetShared',
        'budgetName',
        'channels',
        'servingStatus',
        'budgetAmount',
        'budgetCurrentTotalSpend',
        'pacing',
        'profileAdjustedPacing',
        'projectedOverspend',
        'budgetBiasStrategy',
        'camp',
        'impressions',
        'clicks',
        'clickThroughRate',
        'costPerClick',
        'conversions',
        'costPerConversion',
        'conversionRate',
        'allowReallocation',
        'bidAdjustmentPercentageOnUnderspend',
        'autoRemoveBlockingKeywordOnUnderspend',
        'useBroadMatchOnUnderspend',
        'openAdSchedulesOnUnderspend',
        'geoTargetExpansionAbsoluteMaxInMiles',
        'proratePartialMonth'
      ]
    },
    {
      ...skeleton,
      key: 'basic-campaign',
      name: 'Basic',
      tables: ['manage-campaign'],
      fields: [
        'selectRow',
        'name',
        'channels',
        'budgetManagerPaused',
        'servingStatus',
        'stat',
        'budgetType',
        'budget',
        'contentTemplateName',
        'cost',
        'spendYesterday',
        'impressions',
        'clicks',
        'clickThroughRate',
        'costPerClick',
        'conversions',
        'conversionRate',
        'costPerConversion',
        'campaignLabels',
        'startDate',
        'endDate'
      ]
    },
    {
      ...skeleton,
      key: 'search-metrics',
      name: 'Search Metrics',
      tables: ['manage-account', 'manage-budget', 'manage-campaign'],
      fields: [
        'selectRow',
        'budgetShared',
        'budgetName',
        'name',
        'channels',
        'servingStatus',
        'cost',
        'optimizedDailyAmount',
        'optimizationScore',
        'impressions',
        'impressionShare',
        'avgLostToBudget',
        'avgLostToRank',
        'clicks',
        'clickThroughRate',
        'costPerClick',
        'conversions',
        'callConversions',
        'webConversions',
        'storeConversions',
        'pageViewConversions',
        'otherConversions',
        'conversionRate',
        'costPerConversion',
        'leads',
        'costPerLead'
      ]
    },
    {
      ...skeleton,
      key: 'social-metrics',
      name: 'Social Metrics',
      tables: ['manage-account', 'manage-budget', 'manage-campaign'],
      fields: [
        'selectRow',
        'name',
        'budgetShared',
        'budgetName',
        'channels',
        'servingStatus',
        'cost',
        'impressions',
        'costPerThousandImpressions',
        'uniqueImpressions',
        'clicks',
        'clickThroughRate',
        'costPerClick',
        'conversions',
        'conversionRate',
        'costPerConversion',
        'frequency',
        'reach',
        'costPerThousandReach',
        'views',
        'videoViews',
        'leads',
        'webLeads',
        'platformLeads',
        'costPerLead',
        'leadReachRate',
        'engagement',
        'uniqueClicks',
        'uniqueClickThroughRate',
        'uniqueOutboundClicks',
        'uniqueLandingPageViews',
        'uniqueLandingPageViewRate',
        'costPerUniqueLandingPageView'
      ]
    },
    {
      ...skeleton,
      key: 'budgeting',
      name: 'Budgeting',
      tables: ['manage-account', 'manage-budget', 'manage-campaign'],
      fields: [
        'selectRow',
        'name',
        'budgetShared',
        'budgetName',
        'channels',
        'servingStatus',
        'budgetManagerPaused',
        'budgetType',
        'cost',
        'spendYesterday',
        'budgetCurrentTotalSpend',
        'pacing',
        'approvedBudgetPacing',
        'projectedOverspend',
        'profileAdjustedPacing',
        'budgetSpendSoFar',
        'approvedAccountBudget',
        'budgetAmount',
        'budget',
        'nextApprovedAccountBudget',
        'nextBudgetAmount',
        'conservativeBudgetIncrease',
        'accountAppliedBudget',
        'lifetimeBudget',
        'lifetimeCost',
        'totalLifetimeCost',
        'campaignGroupName',
        'camp',
        'budgetBiasStrategy',
        'allowReallocation',
        'bidAdjustmentPercentageOnUnderspend',
        'autoRemoveBlockingKeywordOnUnderspend',
        'useBroadMatchOnUnderspend',
        'openAdSchedulesOnUnderspend',
        'geoTargetExpansionAbsoluteMaxInMiles',
        'proratePartialMonth'
      ]
    },
    {
      ...skeleton,
      key: 'facebookAdset',
      name: 'AdSet Social',
      tables: ['manage-adGroup'],
      fields: [
        'selectRow',
        'name',
        'channels',
        'servingStatus',
        'stat',
        'campaignPlanName',
        'contentTemplateName',
        'budgetWeight',
        'startDate',
        'endDate',
        'deliveryEstimateStatus',
        'deliveryEstimateLowerBound',
        'cost',
        'impressions',
        'costPerThousandImpressions',
        'clicks',
        'uniqueClicks',
        'clickThroughRate',
        'uniqueImpressions',
        'frequency',
        'reach',
        'costPerThousandReach'
      ]
    },
    {
      ...skeleton,
      key: 'pinterest-metrics',
      name: 'Pinterest Metrics',
      tables: ['manage-account', 'manage-budget', 'manage-campaign'],
      capability: 'Pinterest',
      fields: [
        'selectRow',
        'name',
        'budgetShared',
        'budgetName',
        'channels',
        'servingStatus',
        'cost',
        'impressions',
        'costPerThousandImpressions',
        'uniqueImpressions',
        'clicks',
        'clickThroughRate',
        'costPerClick',
        'conversions',
        'conversionRate',
        'costPerConversion',
        'pinClicks',
        'repins',
        'repinRate',
        'engagement',
        'engagementRate',
        'outboundClicks',
        'outboundClickRate',
        'webSessions'
      ]
    },
    {
      ...skeleton,
      key: 'pinterest-adgroup',
      name: 'Pinterest Metrics',
      tables: ['manage-adGroup'],
      capability: 'Pinterest',
      fields: [
        'selectRow',
        'name',
        'channels',
        'servingStatus',
        'stat',
        'campaignPlanName',
        'contentTemplateName',
        'bidAmount',
        'budgetWeight',
        'cost',
        'impressions',
        'costPerThousandImpressions',
        'uniqueImpressions',
        'clicks',
        'clickThroughRate',
        'costPerClick',
        'conversions',
        'conversionRate',
        'costPerConversion',
        'pinClicks',
        'repins',
        'repinRate',
        'engagement',
        'engagementRate',
        'outboundClicks',
        'outboundClickRate',
        'webSessions'
      ]
    },
    {
      ...skeleton,
      key: 'pinterest-creative',
      name: 'Pinterest Metrics',
      tables: ['manage-creative'],
      capability: 'Pinterest',
      fields: [
        'selectRow',
        'creativePlan',
        'channels',
        'servingStatus',
        'stat',
        'campaignPlanName',
        'adGroupPlanName',
        'creativeType',
        'destinationUrl',
        'cost',
        'impressions',
        'costPerThousandImpressions',
        'uniqueImpressions',
        'clicks',
        'clickThroughRate',
        'costPerClick',
        'conversions',
        'conversionRate',
        'costPerConversion',
        'pinClicks',
        'repins',
        'repinRate',
        'engagement',
        'engagementRate',
        'outboundClicks',
        'outboundClickRate',
        'webSessions'
      ]
    },
    {
      ...skeleton,
      key: 'pinterest-keywords',
      name: 'Pinterest Metrics',
      tables: ['manage-keyword'],
      capability: 'Pinterest',
      fields: [
        'selectRow',
        'creativePlan',
        'channels',
        'servingStatus',
        'stat',
        'campaignPlanName',
        'adGroupPlanName',
        'creativeType',
        'destinationUrl',
        'cost',
        'impressions',
        'costPerThousandImpressions',
        'uniqueImpressions',
        'clicks',
        'clickThroughRate',
        'costPerClick',
        'conversions',
        'conversionRate',
        'costPerConversion',
        'pinClicks',
        'repins',
        'repinRate',
        'engagement',
        'engagementRate',
        'outboundClicks',
        'outboundClickRate',
        'webSessions'
      ]
    },
    {
      ...skeleton,
      key: 'pinterest-criterion',
      name: 'Pinterest Metrics',
      tables: ['manage-criterion'],
      capability: 'Pinterest',
      fields: [
        'criterionType',
        'criterionSpec',
        'status',
        'criterionBid',
        'criterionExcluded',
        'cost',
        'impressions',
        'costPerThousandImpressions',
        'uniqueImpressions',
        'clicks',
        'clickThroughRate',
        'costPerClick',
        'conversions',
        'conversionRate',
        'costPerConversion',
        'engagement',
        'repins',
        'webSessions',
        'outboundClicks',
        'repinRate',
        'engagementRate',
        'outboundClickRate'
      ]
    },
    {
      ...skeleton,
      key: 'pinterest-extensions',
      name: 'Pinterest Metrics',
      tables: ['manage-extensions'],
      capability: 'Pinterest',
      fields: [
        'extensionName',
        'extensionType',
        'servingStatus',
        'extensionStatus',
        'contentTemplateName',
        'campaignPlanName',
        'adGroupPlanName',
        'cost',
        'impressions',
        'clicks',
        'clickThroughRate',
        'costPerClick',
        'conversions',
        'conversionRate',
        'costPerConversion',
        'engagement',
        'repins',
        'webSessions',
        'outboundClicks',
        'repinRate',
        'engagementRate',
        'outboundClickRate'
      ]
    }
  ]
}

const getConfigsForTable = (tableId, capabilities) => {
  const configWithCapabilities = []
  config.configs.forEach(config => {
    if (config.capability) {
      if (capabilities[config.capability]) {
        configWithCapabilities.push(config)
      }
    } else {
      configWithCapabilities.push(config)
    }
  })
  return configWithCapabilities.filter(fig => fig.tables.includes(tableId))
}

export default { getConfigsForTable }
