<template>
  <div>
    <div class="d-flex justify-content-between mb-2 over-highlight align-items-center customSet" :key="customSet.key">
      <b-form-checkbox :value="customSet.key">{{ customSet.name }}</b-form-checkbox>
      <div class="hover-item py-1" style="flex: 0 0 92px;">

        <!--Remove column set-->
        <b-button variant="link"
                  size="sm"
                  class="text-secondary p-0 mr-2"
                  @click.stop="$emit('removeColumn', customSet)">
          <fluency-icon type="delete" />
        </b-button>

        <!--Share column set-->
        <span class="p-0 mr-2 cursor-pointer hover-icon"
              v-p-tooltip.top="'Share with your coworkers'"
              @click="toggleSavePanel('shareColumnOpen')">
          <fluency-icon type="share" />
        </span>

        <!--Edit name of set-->
        <span class="p-0 mr-2 cursor-pointer hover-icon"
              v-p-tooltip.top="'Edit the Name'"
              @click="toggleSavePanel('editNameOpen')">
          <fluency-icon type="edit" />
        </span>

        <!--Save current columns into column set-->
        <b-button variant="link"
                  size="sm"
                  class="p-0 ml-2"
                  v-p-tooltip.top="'Save the selected columns into this one'"
                  @click.stop="$emit('saveColumnsIntoSet', customSet)">
          <fluency-icon type="save" />
        </b-button>
      </div>
    </div>

    <!--Share column set-->
    <simple-cancel-save v-model="customSet.name"
                        :show="editNameOpen"
                        @update:show="editNameOpen = !editNameOpen"
                        @save="$emit('saveColumn')">
      <template #edit="{ value, update, validate }">
        <b-form-group label="Edit the Name">
          <b-form-input :value="value" @change="update($event); validate($event && $event.length > 0)"/>
        </b-form-group>
      </template>
    </simple-cancel-save>

    <!--Edit name of set-->
    <simple-cancel-save :value="''"
                        :show="shareColumnOpen"
                        @update:show="shareColumnOpen = !shareColumnOpen"
                        @save="$emit('shareColumn', {columnSet: customSet, shareWith: $event})">
      <template #edit="{ value, update }">
        <b-form-group label="Share With Coworkers">
          <b-form-input :value="value"
                        @change="update"
                        @focus.stop
                        @click.stop
                        placeholder="myCoworker@awesome.agency"
                        type="email"
                        multiple />
        </b-form-group>
      </template>
    </simple-cancel-save>
  </div>
</template>

<script>
import SimpleCancelSave from '@/components/common/simpleCancelSave'

export default {
  name: 'ColumnEditActions',
  components: { SimpleCancelSave },
  props: ['customSet'],
  data () {
    return {
      saveAsOpen: false,
      editNameOpen: false,
      shareColumnOpen: false
    }
  },
  methods: {
    toggleSavePanel (type) {
      if (type === 'saveAsOpen') {
        this.saveAsOpen = !this.saveAsOpen
        this.editNameOpen = false
        this.shareColumnOpen = false
      }
      if (type === 'editNameOpen') {
        this.editNameOpen = !this.editNameOpen
        this.saveAsOpen = false
        this.shareColumnOpen = false
      }
      if (type === 'shareColumnOpen') {
        this.shareColumnOpen = !this.shareColumnOpen
        this.editNameOpen = false
        this.saveAsOpen = false
      }
    }
  },
}
</script>
