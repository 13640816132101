<template>
  <div class="sub-table-advanced-filtering">
    <b-form-group v-if="filterData && typeof filterData.showOnlyErrors !== 'undefined'">
      <b-form-checkbox :checked="filterData.showOnlyErrors" @change="filterData.showOnlyErrors = $event; updateData('showOnlyErrors' )" class="show-only-errors">Show Only Errors</b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'subTableAdvancedFiltering',
  props: ['advancedFilterData'],
  data () {
    return {
      filterData: {}
    }
  },
  watch: {
    advancedFilterData: {
      handler (newValue, oldValue) {
        this.$setCompat(this, 'filterData', newValue)
      },
      immediate: true
    }
  },
  methods: {
    updateData () {
      this.$emit('update:advancedFilterData', this.filterData)
    }
  }
}
</script>

<style lang="scss">
.sub-table-advanced-filtering .custom-checkbox label.custom-control-label {
  font-weight: normal;
}
</style>
