<template>
  <!--Average-->
  <span v-if="showAverage && cellData && field.summable && field.summable.average && row.accountPlanIds">
    {{dynamicFilter(cellData / row.accountPlanIds.length, field.summable.format)}}
  </span>

  <!--Total-->
  <span v-else-if="cellData">
    {{field.summable ? dynamicFilter(cellData, field.summable.format) : cellData}}
  </span>

  <span v-else-if="cellData === 0">
    0
  </span>
</template>

<script>

export default {
  name: 'SuperCell',
  props: {
    row: {
      type: Object,
      default: () => {}
    },
    field: {
      type: Object,
      default: () => {}
    },
    showAverage: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    cellData () {
      let key = this.field.key
      if (key === 'accountAppliedBudget') {
        key = 'budget'
      }
      return this.row[key]
    }
  },
  methods: {
    dynamicFilter (inp, type = 'default') {
      if (isNaN(inp)) {
        return ' - '
      }

      switch (type) {
        case 'currency':
        case 'currency-precise':
          return this.$filters.currency(inp, 2)
        case 'percentage':
          return this.$filters.percentage(inp, 1)
        case 'number':
          return this.$filters.number(inp, 0, true)
        case 'fixed-2':
          return this.$filters.number(inp, 2, true)
        default:
          return inp
      }
    }
  }
}
</script>
