<template>
  <div>
    <popover-cancel-save :value="filterValue"
                         :use-table-action-container="false"
                         ok-text="Apply"
                         ref="popover"
                         @save="applyFilter($event)">
      <template #trigger-content>
        <b-badge pill class="filter-badge">
          <span>{{badgeText}}</span>
          <t-button-inline @click.stop="remove()" class="pl-2">
            <fluency-icon type="close" />
          </t-button-inline>
        </b-badge>
      </template>
      <template #edit="{update, value}">
        <template v-if="type !== 'java.lang.Boolean'">
          <label class="d-inline">{{text}}</label>
          <div class="d-inline">
            <info-tooltip v-if="isNumeric" class="pr-1"
                          title="Advanced filtering operators help you customize your view based on if those items meet desired conditions.  Supported operators include:
        less than ( < )
        less than or equal to ( <= )
        greater than  ( > )
        greater than or equal to  ( >= )">
            </info-tooltip>
            <info-tooltip v-else-if="!options" class="pr-1" title="Enter the text you’d like to match.  Wildcards ( * ) are supported"></info-tooltip>
          </div>
        </template>
        <div>
          <b-checkbox v-if="typeIsBoolean" :checked="value" @input="update($event)" class="mt-lg-5 mb-4">{{text}}</b-checkbox>
          <b-form-select v-else-if="options" :value="value" @input="update($event)" :options="computedOptions" class="my-4">
            <template #first>
              <b-form-select-option :value="value" disabled>Select One Or More</b-form-select-option>
            </template>
          </b-form-select>
          <bubble-input class="pt-4"
                        v-else
                        autofocus
                        :value="value" @input="update($event)" />
        </div>
      </template>
    </popover-cancel-save>
  </div>
</template>

<script>
import BubbleInput from '../common/forms/bubbleInput'
import InfoTooltip from '../common/forms/infoTooltip'
import PopoverCancelSave from '../common/popoverCancelSave.vue'

export default {
  name: 'advancedFilterEditor',
  components: { PopoverCancelSave, InfoTooltip, BubbleInput },
  props: ['value', 'text', 'show', 'type', 'filterKey', 'options'],
  data () {
    return {
      showPopover: false,
      filterValue: [],
      activeInputText: ''
    }
  },
  watch: {
    value: {
      handler (newVal) {
        this.filterValue = newVal
      },
      immediate: true
    }
  },
  computed: {
    typeIsBoolean () {
      return this.type === 'java.lang.Boolean'
    },
    badgeText () {
      let text = this.text
      if (this.typeIsBoolean) {
        text += ` is ${this.filterValue}`
      } else if (this.value && this.value.length && this.value.length > 0) {
        text += ` contains any ${this.$filters.truncate(this.value.join(', '), 100)}`
      }
      return text
    },
    isNumeric () {
      return this.type === 'java.lang.Double' || this.filterKey?.startsWith('metric.')
    },
    computedOptions () {
      return this.options.filter(option => !!option).map(option => { return { value: [...this.filterValue, option], text: option } })
    }
  },
  methods: {
    applyFilter (value) {
      if (this.typeIsBoolean) {
        this.$emit('input', value)
        this.$emit('apply')
      } else {
        if (value.length > 0) {
          this.$emit('input', value)
          this.$emit('apply')
        } else {
          this.$toast('Nothing to apply')
        }
      }
    },
    remove () {
      this.$emit('delete')
    }
  }
}
</script>

<style lang="scss">
 .manage-filter-popover {
   max-height: 80vh;
 }
</style>
