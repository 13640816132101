<template>
  <div>
    <template v-for="(attachedFeed, attachedFeedIndex) in value" :key="`attachedFeed-${attachedFeedIndex}`">
      <div  v-p-tooltip.top="`${attachedFeed.placeholderTypes} - ${attachedFeed.name}`" class="d-inline">
        <fluency-icon :type="placeholderTypes[attachedFeed.placeholderTypes]" class="text-muted mx-1"/>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'attachedFeeds',
  props: ['value'],
  data () {
    return {
      placeholderTypes: {
        'CALL': 'iphone',
        'SITELINK': 'link',
        'CALLOUT': 'announce'
      }
    }
  }
}
</script>

<style scoped>

</style>
