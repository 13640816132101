<template>
  <popover-cancel-save
    :value="keywordItem[field]"
    :title="title"
    @save="$emit('save', { ...keywordItem, [field]: $event })"
  >
    <template #edit="{value, update}">
      <label class="mb-0">{{label}}</label>
      <div class="mad-lib-container">
        <basic-mad-lib-input :value="value !== -1 ? value : 0" format="CURRENCY" :min-currency-value="0" @input="update(updateKeywordAndLock(field, value, $event))"></basic-mad-lib-input>
        <locked-field-v3
          class="ml-3"
          :field="field"
          :contentTemplateId="keywordItem.contentTemplateId"
          :value="keywordItem.lockedFields"
          @input="$emit('input', { ...keywordItem, lockedFields: $event })"/>
      </div>
      <div v-if="description" class="text-muted">{{description}}</div>
    </template>
  </popover-cancel-save>
</template>

<script>
import LockedFieldV3 from '@/components/common/lockedFieldV3'
import ManageLockedFieldsMixin from '@/mixins/manageLockedFieldsMixin'
import PopoverCancelSave from '@/components/common/popoverCancelSave'
import BasicMadLibInput from 'core-ui/components/inputs/basicMadLibInput.vue'

export default {
  name: 'KeywordsEditField',
  components: { LockedFieldV3, PopoverCancelSave, BasicMadLibInput },
  mixins: [ManageLockedFieldsMixin],
  props: {
    keywordItem: {
      type: Object,
      required: true
    },
    label: String,
    field: String
  },
  computed: {
    title () {
      return this.label?.replace(' Bid', '')
    },
    description () {
      switch (this.field) {
        case 'exactMatchBid':
          return 'Ads may show on searches that are the same as your keyword. Notation: [keyword]'
        case 'phraseMatchBid':
          return 'Ads may show on searches that include the meaning of your keyword. Notation: "keyword"'
        case 'broadMatchBid':
          return 'Ads may show on searches that relate to your keyword. Notation: keyword'
        default:
          return ''
      }
    }
  },
  methods: {
    updateKeywordAndLock (key, value, event) {
      this.keywordItem[key] = value
      this.keywordItem.lockedFields = this.addLocks(this.keywordItem, key)
      return event
    }
  }
}
</script>

<style lang='scss' scoped>

</style>
